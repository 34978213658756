




























































































































import { Component, Prop, Vue, Ref } from 'vue-property-decorator'
import IconPlus from '@tada/icons/dist/IconPlus.vue'
import IconTimes from '@/components/UI/icons/IconTimes.vue'
import type { QMenu } from 'quasar'
import { tasksStore, teamsStore } from '@/store'
import { TaskColor, Team } from '@tada-team/tdproto-ts'

@Component({
  name: 'EditMenu',
  components: {
    EditMenuDescriptionInput: () => import('./EditMenuDescriptionInput.vue'),
    EditMenuSection: () => import('./EditMenuSection.vue'),
    TagSelector: () => import('@/components/UI/TagSelectorNew.vue'),
    TaskImportanceSelector: () => import('./TaskImportanceSelector/index.vue'),
    TaskSectionSelector: () => import('@/components/UI/TaskSectionSelector.vue'),
    TaskStatusSelector: () => import('@/components/UI/TaskStatusSelector.vue'),
  },
})
export default class EditMenu extends Vue {
  @Prop({
    type: Object,
    required: true,
  }) readonly rule!: TADA.TasksColorsRule

  @Prop({
    type: Boolean,
    default: false,
  }) readonly isNew!: boolean

  // functional icons signatures
  iconPlus!: any
  iconTimes!: any

  isLoading = false
  hoveredColorIndex = null
  editedRule: Partial<TADA.TasksColorsRule> = {}
  importanceHasError = false
  descriptionHasError = false

  @Ref() readonly menu!: QMenu

  get tasksColors (): TaskColor[] {
    return tasksStore.state.colors
  }

  get hasError (): boolean {
    return this.importanceHasError || this.descriptionHasError
  }

  get team (): Team {
    return teamsStore.getters.currentTeam
  }

  get canSave (): boolean {
    if (this.hasError) return false

    // if nothing changed
    if (
      !this.tagsChanged &&
      !this.sectionChanged &&
      !this.descriptionChanged &&
      !this.colorChanged &&
      !this.statusChanged &&
      !this.importanceChanged
    ) return false

    // at least one of these must be true for rule to be applied to any tasks
    if (
      !this.editedRule.sectionEnabled &&
      !this.editedRule.tagsEnabled &&
      !this.editedRule.taskImportanceEnabled &&
      this.editedRule.taskStatus === null
    ) return false

    return true
  }

  get tagsChanged (): boolean {
    if (this.editedRule.tagsEnabled !== this.rule.tagsEnabled) return true

    /**
     * prevent false positive when tagsEnabled was false
     * user activated it (set to true)
     * changed tags value inside
     * deactivated it back (set to false)
     */
    if (!this.editedRule.tagsEnabled) return false

    if (
      this.editedRule.tags?.length !== this.rule.tags?.length ||
      this.editedRule.tags?.some(tag => !this.rule.tags?.includes(tag))
    ) return true
    return false
  }

  get sectionChanged (): boolean {
    if (this.editedRule.sectionEnabled !== this.rule.sectionEnabled) return true

    /**
     * prevent false positive when sectionEnabled was false
     * user activated it (set to true)
     * changed section value inside
     * deactivated it back (set to false)
     */
    if (!this.editedRule.sectionEnabled) return false

    return this.editedRule.section !== this.rule.section
  }

  get descriptionChanged (): boolean {
    const newD = this.editedRule.description
    const oldD = this.rule.description || null
    if (newD === oldD) return false

    // because description input emits empty string when cleared
    const newDempty = !newD || newD.length === 0
    const oldDEmpty = !oldD || oldD.length === 0
    if (newDempty && oldDEmpty) return false

    return true
  }

  get colorChanged (): boolean {
    return this.editedRule.colorIndex !== this.rule.colorIndex
  }

  get statusChanged (): boolean {
    return this.editedRule.taskStatus !== this.rule.taskStatus
  }

  get importanceChanged (): boolean {
    if (this.editedRule.taskImportanceEnabled !== this.rule.taskImportanceEnabled) return true

    /**
     * prevent false positive when taskImportanceEnabled was false
     * user activated it (set to true)
     * changed section value inside
     * deactivated it back (set to false)
     */
    if (!this.editedRule.taskImportanceEnabled) return false

    return this.editedRule.taskImportance !== this.rule.taskImportance
  }

  created () {
    this.iconPlus = IconPlus
    this.iconTimes = IconTimes
  }

  handleImportanceInput (v: number | null): void {
    this.importanceHasError = false
    this.editedRule.taskImportance = v
  }

  handleShow (): void {
    this.editedRule = { ...this.rule }
    this.$emit('show')
  }

  async saveRule (): Promise<void> {
    this.isLoading = true
    this.isNew
      ? await tasksStore.actions.createColorsRule(this.editedRule)
      : await tasksStore.actions.updateColorsRule(this.editedRule)
    this.isLoading = false
    this.close()
  }

  show () {
    this.menu.show()
  }

  close () {
    this.$emit('hide')
    this.menu.hide()
  }

  toggleTaskStatus () {
    this.editedRule.taskStatus = this.editedRule.taskStatus
      ? null
      : tasksStore.state.statuses[0]?.uid
  }
}
